import React from "react";
import { useField } from "formik";
import { FormControlLabel, Checkbox } from "@material-ui/core";

const MyCheckbox = ({ label, ...props }) => {
  const [field] = useField(props);
  return (
    <FormControlLabel
      style={{ display: "table" }}
      control={
        <div style={{ display: "table-cell" }}>
          <Checkbox {...field} color="primary" />
        </div>
      }
      label={label}
    />
  );
};

export default MyCheckbox;
