import * as Yup from "yup";

const NUMBERONLY = /^[0-9\s]+$/;
const CHARTERONLY = /^[aA-zZZöÖüÜäÄß\s]+$/;
const ALPHANUMERIC = /^[0-9aA-zZöÖüÜäÄß\s]+$/;
const PASSWORD =
  //  /^(?=.*[A-Za-zöÖüÜäÄ])(?=.*\d)(?=.*[\/@$!%*#?&=.:,;°^<>~"'-_`´ß+%§|€])[A-Za-zöÖüÜäÄ\d\/@$!%*#?&=.:,;°^<>~"'-_`´ß+%§|€]{8,}$/;
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-ZöÖüÜäÄß])(?=.*[\/@$!%*#?&=.:,;°^<>~"'_`´ß+%§|€])[A-Za-zöÖüÜäÄ\d\/@$!%*#?&=.:,;°^<>~"'_`´ß+%§|€]{8,}$/;

const ACCOUNTHOLDER = /^[0-9A-Za-zöÖüÜäÄß&*$%.:'?,(+)/-\s]+$/;
const TAX_NUMBER = /^DE[0-9]|^[\/0-9]{9,15}$/;
// Using built-in methods

function isVallidTaxNumber() {
  return this.matches(TAX_NUMBER, {
    message:
      "Bitte achten Sie auf das richtige Format. Verwenden Sie bitte nur DE gefolgt von Ziffern oder eine Kombination aus Ziffern und /",
    excludeEmptyStrings: true,
  }).required("Steuernummer erforderlich.");
}

function isValidAccountHolder() {
  return this.matches(ACCOUNTHOLDER, {
    message:
      "Es sind nur Zahlen, Buchstaben und folgende Zeichen erlaubt: &*$%.:'?,(+)/-",
    excludeEmptyStrings: true,
  }).required("Kontoinhaber*in ist notwendig.");
}

function isValidPassword() {
  return this.matches(PASSWORD, {
    message:
      "Passwort muss mindestens 8 Zeichen lang, einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten",
    excludeEmptyStrings: true,
  }).required("Bitte geben Sie den Kontoinhaber ein.");
}
function isCharterOnly() {
  return this.matches(CHARTERONLY, {
    message: "nur Zeichen erlaubt.",
    excludeEmptyStrings: true,
  });
}
function isAlphaNumeric() {
  return this.matches(ALPHANUMERIC, {
    message: "nur Zahl und Zeichen erlaubt.",
    excludeEmptyStrings: true,
  });
}

function isNumberOnly() {
  return this.matches(NUMBERONLY, {
    message: "Nur Nummer ist erlaubt.",
    excludeEmptyStrings: true,
  });
}

function isValidPostalCode() {
  return this.matches(NUMBERONLY, {
    message: "Nur Nummer ist erlaubt.",
    excludeEmptyStrings: false,
  }).test("len", "PLZ ist nicht korrekt.", (val) => val?.length === 5);
}
Yup.addMethod(Yup.string, "isValidAccountHolder", isValidAccountHolder);
Yup.addMethod(Yup.string, "isValidPassword", isValidPassword);
Yup.addMethod(Yup.string, "isCharterOnly", isCharterOnly);
Yup.addMethod(Yup.string, "isAlphaNumeric", isAlphaNumeric);
Yup.addMethod(Yup.string, "isNumberOnly", isNumberOnly);
Yup.addMethod(Yup.string, "isValidPostalCode", isValidPostalCode);
Yup.addMethod(Yup.string, "isVallidTaxNumber", isVallidTaxNumber);
